import React from 'react';

import EventItem from './EventItem';

//import * as ROUTES from '../../constants/routes';
//import { Link } from 'react-router-dom';



const EventList = ({
  authUser,
  events,
  onEditEvent,
  onRemoveEvent,
}) => (
  <div className="events-list">
    {events.map(event => (
     
      <EventItem
        authUser={authUser}
        key={event.uid}
        event={event}
        onEditEvent={onEditEvent}
        onRemoveEvent={onRemoveEvent}
      />
               
    ))}
  </div>
);

export default EventList;
