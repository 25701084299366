import React, { Component } from 'react';

import { AuthUserContext } from '../Session';
//import { withFirebase } from '../Firebase';
//import VoteList from './VoteList';

import { compose } from 'recompose';

import { withAuthorization, withEmailVerification } from '../Session';
import * as ROLES from '../../constants/roles';

class VoteNew extends Component {
  constructor(props) {
    super(props);

   this.state = {
            title: '',
            //titleError: '',
            options: [
                { option: '', optionError: '' },
                { option: '', optionError: '' }
            ]
        };


       // this.handleRemoveOption = this.handleRemoveOption.bind(this);
  }

  componentDidMount() {
 //   this.onListenForVotes();
  }

/*
  onListenForVotes = () => {
    this.setState({ loading: true });

    this.props.firebase
      .votes()
      .orderByChild('createdAt')
      .limitToLast(this.state.limit)
      .on('value', snapshot => {
        const voteObject = snapshot.val();

        if (voteObject) {
          const voteList = Object.keys(voteObject).map(key => ({
            ...voteObject[key],
            uid: key,
          }));

          this.setState({
            votes: voteList,
            loading: false,
          });
        } else {
          this.setState({ votes: null, loading: false });
        }
      });
  };
  */

  componentWillUnmount() {
    this.props.firebase.votes().off();
  }

  onChangeTitle =  event => {
    this.setState({ title: event.target.value });
  };

  onChangeOption = (i, event) => {

  //  options: [
  //              { option: '', optionError: '' },
  //              { option: '', optionError: '' }
  //          ]
    //value={this.state.options[i].option}
    // this.setState({ title: event.target.value });
  //  let i = event.target.data_id;
   // let optionsNew = this.state.options;
    // optionsNew[i].option = event.target.value;

     let options = this.state.options;
        options[i].option = event.target.value;
        this.setState({ options: options });


     // this.setState({ title: i });
  }



  onCreateVote = (event, authUser) => {
    let key = this.props.firebase.votes().push({
      title: this.state.title,
      userId: authUser.uid,
      createdAt: this.props.firebase.serverValue.TIMESTAMP,
       options: this.state.options
    }).key;

    this.setState({ 
      title: '',
       options: [
                { option: '', optionError: '' },
                { option: '', optionError: '' }
            ]
    });


   

    //options
    let options = this.state.options;
    let fireKey = this.props.firebase;

    options.forEach((item) => (

      fireKey.voteOptions().push({
        option: item,
        key:key
       })

      ));
    

    event.preventDefault();
  };

  onEditVote = (vote, title) => {
    const { uid, ...voteSnapshot } = vote;

    this.props.firebase.vote(vote.uid).set({
      ...voteSnapshot,
      title,
      editedAt: this.props.firebase.serverValue.TIMESTAMP,
    });
  };

  onRemoveVotes = uid => {
    this.props.firebase.vote(uid).remove();
  };

  onNextPage = () => {
    this.setState(
      state => ({ limit: state.limit + 5 }),
      this.onListenForVotes,
    );
  };





     handleAddOption= () => {
        let options = this.state.options;
        options.push({ option: '', optionError: '' });

        this.setState({ options }); //es6 shorthand for { options: options }

        //event.preventDefault();
    }


    handleRemoveOption = (i) => {
        let options = this.state.options;

         options.splice(i, 1);

        this.setState({ options });
    }



  render() {



        let options = this.state.options.map((option, i) => {
            return (
                <div className="votenew__option voteoption" key={i}>
                    <br />
                    <input
                      className="voteoption__input"
                     onChange={this.onChangeOption.bind(this, i)}
                    	type="text"
                        //floatingLabelText={`Option ${i + 1}`}
                        value={this.state.options[i].option}
                        data_id={i}
                       // onChange={this.handleOptionChange.bind(this, i)}
                      //  errorText={this.state.options[i].optionError}
                      required="required"
                        />
                        <span className="votenew__remove-option voteoption__remove" onClick={() => this.handleRemoveOption(i)/*this.handleRemoveOption()*/}>x</span>
                </div>
            );
        });



    //const { votes } = this.state;
    const title = this.state.title;

    return (
      <AuthUserContext.Consumer>
        {authUser => (


          <div className="votenew">


           

            <form
              onSubmit={event =>
                this.onCreateVote(event, authUser)
              }
            >

            <p>Назва: </p>
             <input
                type="text"
                value={title}
                onChange={this.onChangeTitle}
                required="required"
              />

            <p>Варіанти відповіді</p>
            {options}

            <div className="btn" onClick={this.handleAddOption}>Додати варіант відповіді</div>
             
              <input className="btn" type="submit" value="Створити голосування"/>
            </form>
          </div>


        )}
      </AuthUserContext.Consumer>
    );
  }
}

const condition = authUser =>
  authUser && !!authUser.roles[ROLES.ADMIN];

export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(VoteNew);


