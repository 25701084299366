import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { compose } from 'recompose';

import { withAuthorization, withEmailVerification } from '../Session';
import { UserList, UserItem } from '../Users';
import * as ROLES from '../../constants/roles';
import * as ROUTES from '../../constants/routes';


//import { Link } from 'react-router-dom';




import Votes from '../Votes';
import VoteNew from '../Votes/VoteNew';
//import VoteItem from '../Votes/VoteItem';

import EventNew from '../Events/EventNew';
import Events from '../Events';

import News from '../News';
import NewsNew from '../News/NewsNew';


import Points from '../Points';
import PointNew from '../Points/PointNew';

//import { withFirebase } from '../Firebase';


import Tabs from '../Tabs';




import FcmApp from '../Firebase/fcm.js';


//import { askForPermissioToReceiveNotifications } from '../Firebase/firebase.js';



//import { messaging } from '../Firebase/firebase.js';

/*

const NotificationButton = () => (



    <button onClick={askForPermissioToReceiveNotifications} >
      Click to receive notifications
    </button>
);

*/




const AdminPage = (props) => (
  <div className="admin">
    <h1>Панель адміністратора</h1>

{/*className="admin__tabs"*/}


     <Tabs>
     	<div label="Користувачі">
     		<div className="admin__users">
     			
<h1>Користувачі</h1>
<div className="admin__item">
<div className="admin__left">
<p>Додати користувача</p>
</div>
<div className="admin__right">
 <Switch>
      <Route exact path={ROUTES.ADMIN_DETAILS} component={UserItem} />
      <Route exact path={ROUTES.ADMIN} component={UserList} />

       
    </Switch>
</div>
</div>

     		</div>
     	</div>

     	<div label="Події">
     		<div className="admin__events">
     			
     			<h1>Події</h1>
<div className="admin__item">
<div className="admin__left">
<EventNew />
</div>
<div className="admin__right">
<Events />
</div>
</div>


     		</div>
     	</div>

     	<div label="Новини">
     		<div className="admin__news">
     			
     			<h1>Новини</h1>
<div className="admin__item">
<div className="admin__left">
<p>Додати новину</p>
<NewsNew />
</div>
<div className="admin__right">
<News />
</div>
</div>



     		</div>
     	</div>

     	<div label="Голосування">
     		<div className="admin__votes">
     			

<h1>Голосування</h1>
<div className="admin__item">
<div className="admin__left">
<VoteNew />
</div>
<div className="admin__right">
<Votes />
</div>
</div>


     		</div>
     	</div>





     	<div label="Бали">
     		<div className="admin__points">
     			<h1>Рейтинг</h1>
<div className="admin__item">
<div className="admin__left">
<p>Додати бал</p>
<PointNew />
</div>
<div className="admin__right">
<p>Користувачі</p>
<Points />
</div>
</div>

     		</div>
     	</div>

{/*
         <div label="Пуш-повідомлення">
             <div className="admin__push">
                                 <h1>Push</h1>
                <div className="admin__item">
                <div className="admin__left">
                <p>Надіслати повідомлення</p>
                <FcmApp />
               
                </div>
                <div className="admin__right">
                <p>Push</p>
                </div>
                </div>

             </div>
         </div>
*/}

     </Tabs>










  </div>
);

const condition = authUser =>
  authUser && !!authUser.roles[ROLES.ADMIN];

export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(AdminPage);
