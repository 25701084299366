import React from 'react';
import { Link } from 'react-router-dom';

import { AuthUserContext } from '../Session';
import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';

//import smLogo from '../../images/sm-logo.png'


const MobileNavigationButton = () => (
 <>
   <div onClick={ () => { 

     if(document.querySelector(".mobilemenu").style.display != "block") {
        document.querySelector(".mobilemenu").style.display = "block";
        document.querySelector(".mobilemenu").style.height = "auto";
      } else {
        document.querySelector(".mobilemenu").style.display = "none";
        document.querySelector(".mobilemenu").style.height = "0px";
      } 

    }} 
      className="mobilemenu-button"><span></span></div>
 </>

);


const MobileNavigation = () => (
  <>
    <MobileNavigationButton />
    <Navigation />
  </>

 );

const Navigation = () => (
  <AuthUserContext.Consumer>





    {authUser =>
      authUser ? (
        <>

        
        { window.innerWidth >= 768 ? (
  <NavigationAuth className="desktopmenu" authUser={authUser} />
  ) : (
  <>
  <MobileNavigationButton />
  <NavigationAuth className="mobilemenu" authUser={authUser} />
  </>
  )}

 
            </>
      ) : (
        <NavigationNonAuth />
      )
    }


  </AuthUserContext.Consumer>
);

const NavigationAuth = ( props ) => (
  <ul id="main_nav" className={props.className}>
    <li>
      <Link to={ROUTES.HOME}>Головна </Link>
    </li>
    <li>
      <Link to={ROUTES.NEWS}>Новини</Link>
    </li>
    <li>
      <Link to={ROUTES.VOTES}>Голосування</Link>
    </li>
    <li>
      <Link to={ROUTES.EVENTS}>Події</Link>
    </li>
   {/*
    <li>
      <Link to={ROUTES.CHAT}>Chat</Link>
    </li>
  */}
    <li>
      <Link to={ROUTES.ACCOUNT}>Аккаунт</Link>
    </li>
    {!!props.authUser.roles[ROLES.ADMIN] && (

      <li>
           <Link to={ROUTES.ADMIN}>Адмінка</Link>
        </li>


     
    )}
    <li>
      <SignOutButton />
    </li>
  </ul>
);

const NavigationNonAuth = () => (
  <>
{/*
  <ul id="main_nav">
    <li>
      <Link to={ROUTES.START}>Стартова</Link>
    </li>
    <li>
      <Link to={ROUTES.SIGN_IN}>Ввійти</Link>
    </li>
  </ul>
*/}
  </>
);


/*

const NavigationAdmin = () => (
  <ul id="admin_nav" className="submenu">
        <li>
           <Link to={ROUTES.ADMIN}>Адмінка</Link>
        </li>
       <li>
           <Link to={ROUTES.ADD_VOTE}>Add vote</Link>
        </li>
      </ul>
  );
*/

export default Navigation;


/*
 <li className="admin_nav">Адмінка
          <NavigationAdmin />
      </li>
      */

      /*
       <li>
          <Link to={ROUTES.CHAT_ADMIN}>Chat admin</Link>
         </li>
         */

         /*
                 <li>
         <Link to={ROUTES.ADD_NEWS}>Add news</Link>
        </li>
        */