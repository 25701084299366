import React, { Component } from 'react';

import { AuthUserContext } from '../Session';
//import { withFirebase } from '../Firebase';
import NewsList from './NewsList';


import { compose } from 'recompose';

import { withAuthorization, withEmailVerification } from '../Session';

//import * as ROLES from '../../constants/roles';



class News extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: '',
      loading: false,
      news: [],
      limit: 5,
    };
  }

  componentDidMount() {
    this.onListenForNews();
  //  this.onListenForPush();
  }
/*

  onListenForPush = () => {

    const messaging = this.props.firebase.messaging;

// Add the public key generated from the console here.
//messaging.getToken({vapidKey: "BPI2qVbPFlT5i8NoLupVoeh8c9hAUTcg2b7b0k2g6SnEcmQWpN01XwrJlqfyq1v_Q8W1B9f2wC9OQ0wSAjo7ZuY"});
// Get registration token. Initially this makes a network call, once retrieved
// subsequent calls to getToken will return from cache.
messaging.getToken({ vapidKey: 'BPI2qVbPFlT5i8NoLupVoeh8c9hAUTcg2b7b0k2g6SnEcmQWpN01XwrJlqfyq1v_Q8W1B9f2wC9OQ0wSAjo7ZuY' }).then((currentToken) => {
  if (currentToken) {
    // Send the token to your server and update the UI if necessary
    alert("token");

    // ...
  } else {
    // Show permission request UI
    console.log('No registration token available. Request permission to generate one.');
      alert("ne token");
    // ...
  }
}).catch((err) => {
  console.log('An error occurred while retrieving token. ', err);
    alert("token eroor");
  // ...
});



messaging.onMessage((payload) => {
  console.log('Message received. ', payload);
  alert("mesaage send");
  // ...
});


  }
  */

  onListenForNews = () => {
    this.setState({ loading: true });

    this.props.firebase
      .news()
      .orderByChild('createdAt')
      .limitToLast(this.state.limit)
      .on('value', snapshot => {
        const newsObject = snapshot.val();

        if (newsObject) {
          const newsList = Object.keys(newsObject).map(key => ({
            ...newsObject[key],
            uid: key,
          }));

          const newsListReversed = newsList.reverse();

          this.setState({
            news: newsListReversed,
            loading: false,
          });
        } else {
          this.setState({ news: null, loading: false });
        }
      });
  };

  componentWillUnmount() {
    this.props.firebase.news().off();
  }

  onChangeText = event => {
    this.setState({ text: event.target.value });
  };

  onCreateNews = (event, authUser) => {
    this.props.firebase.news().push({
      text: this.state.text,
      userId: authUser.uid,
      createdAt: this.props.firebase.serverValue.TIMESTAMP,
    });

    this.setState({ text: '' });

    event.preventDefault();
  };

  onEditNews = (news, text) => {
    const { uid, ...newsSnapshot } = news;

    this.props.firebase.newsItem(news.uid).set({
      ...newsSnapshot,
      text,
      editedAt: this.props.firebase.serverValue.TIMESTAMP,
    });
  };

  onRemoveNews = uid => {
    this.props.firebase.newsItem(uid).remove();
  };

  onNextPage = () => {
    this.setState(
      state => ({ limit: state.limit + 5 }),
      this.onListenForNews,
    );
  };

  render() {
    const { news, loading } = this.state;

    return (
      <AuthUserContext.Consumer>
        {authUser => (

          <div className="news-list-wrap">

          {/* !!authUser.roles[ROLES.ADMIN] && (
            <form
              onSubmit={event =>
                this.onCreateNews(event, authUser)
              }>
              <input
                type="text"
                value={text}
                onChange={this.onChangeText}
              />
              <button type="submit">Надіслати</button>
            </form>
          )*/}


            {news && (
              <NewsList
                authUser={authUser}
                news={news}
                onEditNews={this.onEditNews}
                onRemoveNews={this.onRemoveNews}
              />
            )}

            {!news && <div>There are no news ...</div>}

            {!loading && news && (
              <button type="button" onClick={this.onNextPage}>
                Більше новин
              </button>
            )}

            {loading && <div className="lds-dual-ring"></div>}

                      </div>
        )}
      </AuthUserContext.Consumer>
    
    );
  }
}





const condition = authUser => !!authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(News);

//export default withFirebase(News);
