import React, { Component } from 'react';


import { withFirebase } from '../Firebase';
//import { AuthUserContext } from '../Session';


//import * as ROUTES from '../../constants/routes';
//import { Link } from 'react-router-dom';



class VoteResult extends Component {
  constructor(props){
    super(props);

    this.state = {
      result: this.props.result,
      voteId: this.props.voteId,
firebase: this.props.firebase
    }
  }

 

/*
    componentWillUnmount() {
    this.props.firebase.voteAnswers().off();
  }
*/





    render(){

      const result = this.state.result;

      return(


          <div>
 
         <p>answers</p>
        
            <div>
              {result.map((item, index) =>(
                <p>{item}</p>

              ))}
          

            </div>

            
        
            
          </div>

        );

    }
}


class OptionItem extends Component {
  constructor(props){
    super(props);

    this.state = {
      title: this.props.title,
      index: this.props.index,
      voteId: this.props.voteId
    }
  }


  render(){

    let title = this.state.title;
    let index = this.props.index;
    let voteId = this.props.voteId

    return (

        <div onClick={() => {this.props.updateAnswer(this.state.index)}} className="vote-option-item">
          <label htmlFor ={voteId + "_" +  index}>
             <input type="radio" id={voteId + "_" + index} name={voteId} value="" />
             <span>{title}</span>
          </label>
        </div>

      );
  }
}


class VoteButton extends Component {
  constructor(props) {
    super(props)

    this.state = {
      userId: this.props.userId,
       answerId: this.props.answerId,
       voteId: this.props.voteId
    }

  }

onSubmitVote = () => {


      // const { uid, ...messageSnapshot } = message;


    this.props.firebase.voteAnswers().push({
      userId: this.state.userId,
      optionId: this.props.answerId,
      voteId: this.props.voteId,
      votedAt: this.props.firebase.serverValue.TIMESTAMP
    });

      // event.preventDefault();

  }



render(){
  return(

       <button onCLick={this.onSubmitVote}>Vote</button>
    );
}




}

class VoteItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      key: this.props.key,
      editMode: false,
      editText: this.props.vote.text,
      title:this.props.vote.title,
     voteId: this.props.vote.uid,
      answer: "",
      firebase: this.props.firebase,
      result: [],
      authUser: this.props.authUser,
      userWasVoted: false
    };


  //  this.onVote = this.onVote.bind(this);
  }

  componentDidMount(){
    this.onListenForResults();
    //this.userWasVoted();
  }

  onToggleEditMode = () => {
    this.setState(state => ({
      editMode: !state.editMode,
      editText: this.props.vote.text,
    }));
  };

  onChangeEditText = event => {
    this.setState({ editText: event.target.value });
  };

  onSaveEditText = () => {
    this.props.onEditVote(this.props.vote, this.state.editText);

    this.setState({ editMode: false });
  };



  updateAnswer = (index) => {
    this.setState({answer: index})

   // event.preventDefault();
  }

  onSendAnswer = () => {





    this.props.onSubmitVote(this.state.voteId, this.state.answer);

  }

  onSendVote = (authUser) => {


      // const { uid, ...messageSnapshot } = message;

    let userId =  authUser.uid;
    let answerId = this.state.answer;
    let voteId = this.state.voteId;


    if(!this.state.answer){
  alert("Зробіть вибір!");
  return;
}

alert(this.state.answer);

    this.props.firebase.voteAnswers().push({
      userId: userId,
      optionId: answerId,
      voteId: voteId,
      votedAt: this.props.firebase.serverValue.TIMESTAMP
    });

      // event.preventDefault();

  }


  ////////////////////////


  onListenForResults = () => {

   
    this.props.firebase
      .voteAnswers()
      .orderByChild('votedAt')
      .on('value', snapshot => {
        const resultsObject = snapshot.val();

        if (resultsObject) {
          const resultsList = Object.keys(resultsObject).map(key => ({
            ...resultsObject[key],
            uid: key,
          }));


        const resultsListFilter = resultsList.filter((item)=>item.voteId === this.state.voteId);

        //  alert(resultsListFilter);



          this.setState({
            result: resultsListFilter,
            loading: false,
          });


          //check user voted
          if(resultsListFilter.find(item => item.userId === this.state.authUser.uid)){
            this.setState({userWasVoted: true})
          }
          

        } else {
          this.setState({ result: null, loading: false });
        }
      });
    };

//ЯК ПРАВИЛЬНО ЗРОБИТИ ЦЮ ФУНКЦІЮ ОКРЕМО ???? синзрон
userWasVoted = () => {
  //const userId = this.state.authUser.uid;
  const result = this.state.result;


alert(result);
// alert(result.find(item => item.userId == userId));

  if(result.find(item => item.oprionId === "1")){
    alert("!");
    this.setState({userWasVoted: "111111111111111111"})
  }

 //this.setState({userWasVoted: "111111111111111111"})
  //result.find(item => item.userId == userId) ? this.setState({userWasVoted: true}) : this.setState({userWasVoted: false}); // Parsing error: The type cast expression is expected to be wrapped with parenthesis

}


 

  render() {
    const { authUser, vote } = this.props;
       // const {  onRemoveVote, title } = this.props;
    //const { editMode, editText } = this.state;

 
 const onSubmitVote = this.props.onSubmitVote;

    const options = vote.options;
    const optionsArr = options.map(function(item){
            return Object.values(item);
    });

  const  result = this.state.result;
  const voted = this.state.userWasVoted;


  //const ResultMap = new Map();



const resultMap = options.map(function(option, index) {

 // alert(inde);

 let countVotes = result ? result.filter((item)=>item.optionId === index) : [];
 let countVotesNumber = countVotes.length;
 let countPercent = result ? (countVotesNumber * 100 / result.length).toFixed(2) : 0;




  return {option: option.option, count: countVotesNumber, percent: countPercent } ; //array to map 
})

//result.filter((item)=>item.voteId == this.state.voteId)

  // const resultsListFilter = resultsList.filter((item)=>item.voteId == this.state.voteId);



    //Object.keys(vote.options).map(i => vote.options[i]);

    return (

      
      <div key={vote.uid} className="vote-item" data-id={vote.uid}>
       
        <h3>{vote.title}</h3>


         <div>
       {/*
 <Link
                  to={{
                    pathname: `${ROUTES.VOTES}/${vote.uid}`,
                    state: { vote },
                  }}
                >ЛІНК</Link>

       */}
            
          </div>
       


        




      {!voted && (
          <div className="option__list" >

             {optionsArr.map((item, index) =>(
                <OptionItem key={index} updateAnswer={this.updateAnswer} voteId={vote.uid} title={item} id={item} index={index} />
          )) //onAnswer // get option ID !!!! //updateData={this.updateData}onCLick={this.updateAnswer(index)}
        }

        
{console.log(this.state.answer)}
        <button onClick={() => {this.state.answer !== ""  ? onSubmitVote(vote.uid, this.state.answer, authUser.uid) : alert("Вибери відповідь " + this.state.answer) }}>Голосувати!</button>



        </div>
        )}

      {voted && (
          <div>

         <p><strong>Ти проголосував</strong></p>
         <p>Результат:</p>
         
 <div className="result">
         {resultMap.map((item, index) =>(

                 <div className="result__option" key={index}>  
                   <div className="result__title">{item.option}: </div>
                   <span className="result__count">{item.count} голосів </span> 
                   <span className="result__percent">({item.percent} %)</span> 
                   <div style={{width: item.percent + '%' }} className="result__bar"></div>
                 </div>

          
              
          )) //onAnswer // get option ID !!!! //updateData={this.updateData}onCLick={this.updateAnswer(index)}
        }
</div>
 </div>


       
        )}

     


         {/*result.map((item, index) =>(
                 <ul style={{border: "1px solid black"}}>
                   <li>{item.voteId}</li>
                   <li>{item.uid}</li>
                   <li>{item.optionId}</li>
                   <li>{item.userId}</li>

                 </ul>
              
          )) //onAnswer // get option ID !!!! //updateData={this.updateData}onCLick={this.updateAnswer(index)}
        */}

       {/* <p onClick={this.onListenForResults}>Results</p> */}


       {/*<button onClick={this.onSendVote(authUser)}>SUBMIT</button> НЕ ТАК!! ПРАВИЛЬНО ПЕРЕДАТИ СУКУ ! */}
       
      {/* <VoteResult result={this.state.result} voteId={vote.uid} /> */}


       {/*this.result.map((item) => (
          <p>{item}</p>
        ))*/}



        {/* editMode ? (
          <input
            type="text"
            value={editText}
            onChange={this.onChangeEditText}
          />
        ) : (
          <span>
            {vote.editedAt && <span>(Edited)</span>}
          </span>
        ) */}




        {/*authUser.uid === vote.userId && (
          <span>
            {editMode ? (
              <span>
                <button onClick={this.onSaveEditText}>Save</button>
                <button onClick={this.onToggleEditMode}>Reset</button>
              </span>
            ) : (
              <button onClick={this.onToggleEditMode}>Edit</button>
            )}

            {!editMode && (
              <button
                type="button"
                onClick={() => onRemoveVote(vote.uid)}
              >
                Delete
              </button>
            )}
          </span>
        )*/}



      </div>

      
    );
  }
}

//export default VoteItem;
export default withFirebase(VoteItem);
