import React, { Component } from 'react';

import { AuthUserContext } from '../Session';
//import { withFirebase } from '../Firebase';
//import EventList from './EventList';

import { compose } from 'recompose';

import { withAuthorization, withEmailVerification } from '../Session';
import * as ROLES from '../../constants/roles';

import EventMap from './EventMap';



class EventNew extends Component {
  constructor(props) {
    super(props);

   this.state = {
            title: '',
            place: '',
            timeStart: '',
            timeEnd: '',
            lat: '',
            lon: '',
            points: ''
        };
  }

  componentDidMount() {

  }


  componentWillUnmount() {
    this.props.firebase.events().off();
  }

  onChangeTitle = event => {
    this.setState({ title: event.target.value });
  };

   onChangePlace = event => {
    this.setState({ place: event.target.value });
  };

   onChangeTimeStart = event => {

   // let timestamp = new Date(event.target.value).getTime();
   // this.setState({ timeStart: timestamp });

   this.setState({ timeStart: event.target.value });
   document.querySelector('input[name="timeEnd"]').setAttribute("min", event.target.value);

  };

   onChangeTimeEnd = event => {

   this.setState({ timeEnd: event.target.value });

  };



   onChangeLat = event => {
    this.setState({ lat: event.target.value });
  };


   onChangeLon = event => {
    this.setState({ lon: event.target.value });
  };

   onChangePoints = event => {
    this.setState({ points: event.target.value });
  };




  onCreateEvent = (event, authUser) => {
    let key = this.props.firebase.events().push({
      title: this.state.title,
      place: this.state.place,
      timeStart: this.state.timeStart,
      timeEnd: this.state.timeEnd,
      lat: this.state.lat,
      lon: this.state.lon,
      points: this.state.points,
      userId: authUser.uid,
      createdAt: this.props.firebase.serverValue.TIMESTAMP,
    }).key;

    this.setState({ 
      title: '',
      place: '',
      timeStart: '',
      timeEnd: '',
      lat: '',
      lon: '',
      points: ''
    });
    

    event.preventDefault();
  };
//  position={{ lat: 50.435, lng: 30.558 }}

/*
  initMap = () => {
  const myLatlng = { lat: -25.363, lng: 131.044 };
  const map = new window.google.maps.Map(document.getElementById("map"), {
    zoom: 4,
    center: myLatlng,
  });
  // Create the initial InfoWindow.
  let infoWindow = new window.google.maps.InfoWindow({
    content: "Click the map to get Lat/Lng!",
    position: myLatlng,
  });
  infoWindow.open(map);
  // Configure the click listener.
  map.addListener("click", (mapsMouseEvent) => {
    // Close the current InfoWindow.
    infoWindow.close();
    // Create a new InfoWindow.
    infoWindow = new window.google.maps.InfoWindow({
      position: mapsMouseEvent.latLng,
    });
    infoWindow.setContent(
      JSON.stringify(mapsMouseEvent.latLng.toJSON(), null, 2)
    );
    infoWindow.open(map);
  });
}
*/

updateCoords = (coords) => {
  // this.setState({ name: value });
    this.setState({ lon: coords.lon, lat: coords.lat });
}



  render() {



    const { title, place, timeStart, timeEnd, lat, lon, points } = this.state;

    //et timestamp = new Date(startTime).toLocaleFormat("2021-02-18T03:53");

    return (
      <AuthUserContext.Consumer>
        {authUser => (


          <div>


           <h2>Нова подія</h2>

            <form
              onSubmit={event =>
                this.onCreateEvent(event, authUser)
              }
            >

            <div>
            <label>Назва: </label>
             <input
                type="text"
                value={title}
                onChange={this.onChangeTitle}
                required="required"
              />  
              </div>

              <div>
               <label>Місце: </label>
             <input
                type="text"
                value={place}
                onChange={this.onChangePlace}
                 required="required"
              /> 
              </div>


              <div>
            <label>Час початку: </label>
             <input
                type="datetime-local"
                value={timeStart}
                onChange={this.onChangeTimeStart}
              />
              </div>

              <div>
            <label>Час закінчення: </label>
             <input
                type="datetime-local"
                value={timeEnd}
                onChange={this.onChangeTimeEnd}
                name="timeEnd"
                 required="required"
              />
              </div>
              
              <EventMap coords={{lat: 50.435, lng: 30.558 }} updateCoords={this.updateCoords} />

             <div>
              <label>Широта: </label>
             <input
                type="text"
                value={lat}
                onChange={this.onChangeLat}
                name="lat"
                 required="required"
              />
              </div>

              <div>
              <label>Довгота: </label>
             <input
                type="text"
                value={lon}
                onChange={this.onChangeLon}
                name="lon"
                 required="required"
              />
              </div>

              <div>
              <label>Бали: </label>
             <input
                type="number"
                value={points}
                onChange={this.onChangePoints}
                 required="required"
              />
              </div>

        
             
              <button type="submit">Додати подію</button>
            </form>
          </div>


        )}
      </AuthUserContext.Consumer>
    );
  }
}

const condition = authUser =>
  authUser && !!authUser.roles[ROLES.ADMIN];

export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(EventNew);


//"https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places",