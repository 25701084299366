import React, { Component } from 'react';

import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
import EventList from './EventList';


//import Event from './EventItem'; ///&&&&&&& ?????? Do I need it ?


import EventPage from './EventPage'; 

//import * as ROUTES from '../../constants/routes';
//import { Link } from 'react-router-dom';
import { Switch, Route } from 'react-router-dom';


/*
const Events = () => (
<Switch>
      <Route exact path={ROUTES.EVENT} component={EventPage} /> 
     <Route exact path={ROUTES.EVENTS} component={EventPage} /> 
 </Switch>
);
*/

class Events extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: '',
      loading: false,
      events: [],
      limit: 5,
    };
  }

  componentDidMount() {
    this.onListenForEvents();
  }

  onListenForEvents = () => {
    this.setState({ loading: true });

    this.props.firebase
      .events()
      .orderByChild('createdAt')
      .limitToLast(this.state.limit)
      .on('value', snapshot => {
        const eventObject = snapshot.val();

        if (eventObject) {
          const eventList = Object.keys(eventObject).map(key => ({
            ...eventObject[key],
            uid: key,
          }));

           const eventListReversed = eventList.reverse();

          this.setState({
            events: eventListReversed,
            loading: false,
          });
        } else {
          this.setState({ events: null, loading: false });
        }
      });
  };

  componentWillUnmount() {
    this.props.firebase.events().off();
  }





  onEditEvent = (event, title) => {
    const { uid, ...eventSnapshot } = event;

    this.props.firebase.event(event.uid).set({
      ...eventSnapshot,
      title,
      editedAt: this.props.firebase.serverValue.TIMESTAMP,
    });
  };

  onRemoveEvent = uid => {
    this.props.firebase.event(uid).remove();
  };

  onNextPage = () => {
    this.setState(
      state => ({ limit: state.limit + 5 }),
      this.onListenForEvents,
    );
  };

  render() {
    const { events, loading } = this.state;
    //const title = this.state.title;

    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div>



          <div className="events-list-wrap">
           

            {loading && <div className="lds-dual-ring"></div>}

            {events && (

  <EventList
                authUser={authUser}
                events={events}
                onEditEvent={this.onEditEvent}
                onRemoveEvent={this.onRemoveEvent}
              />


           

            )}



           

            {!events && <div>Немає подій ...</div>}

 {!loading && events && (
              <button type="button" onClick={this.onNextPage}>
                Більше подій
              </button>
            )}

              

              </div>
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}










export default withFirebase(Events);


/*
   <EventList
                authUser={authUser}
                events={events}
                onEditEvent={this.onEditEvent}
                onRemoveEvent={this.onRemoveEvent}
              />



                  <Switch>

      <Route exact path='/events' component={() => <EventList events={events} onEditEvent={this.onEditEvent}  onRemoveEvent={this.onRemoveEvent} authUser={authUser}  />} />
    <Route  path='/events/:id' component={EventPage} />

       
    </Switch>

              */