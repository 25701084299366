import React from 'react';
import { compose } from 'recompose';

import { withAuthorization, withEmailVerification } from '../Session';
//import Messages from '../Messages';

//import Votes from '../Votes';

import SignIn from '../SignIn';

import smLogo from '../../images/sm-logo.png';
import smBanner from '../../images/sm-banner.png';

import { AuthUserContext } from '../Session';

//import MessageApp from '../Notification';

window.addEventListener("beforeinstallprompt", (event) => {
   console.log("beforeinstallprompt fired -> ", event);
   window.deferredPrompt = event;
   document.getElementById("yourInstallButtonContainer").classList.toggle("hidden", false);
});


// Code to handle install prompt on desktop

let deferredPrompt;
const addBtn = document.querySelector('.add-button');
//addBtn.style.display = 'none';

window.addEventListener('beforeinstallprompt', (e) => {
  // Prevent Chrome 67 and earlier from automatically showing the prompt
  e.preventDefault();
  // Stash the event so it can be triggered later.
  deferredPrompt = e;
  // Update UI to notify the user they can add to home screen
  addBtn.style.display = 'block';

  addBtn.addEventListener('click', () => {
    // hide our user interface that shows our A2HS button
    addBtn.style.display = 'none';
    // Show the prompt
    deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the A2HS prompt');
      } else {
        console.log('User dismissed the A2HS prompt');
      }
      deferredPrompt = null;
    });
  });
});



const Home = () => (


	 <AuthUserContext.Consumer>





    {authUser =>
      authUser ? (
        <>

        
        <div className="homepage">
    <img src={smBanner} alt="logo" />
    <button className="add-button">Add to home screen</button>
    <h2></h2>


    
 

  </div>
  </>

      ) : (
         <div>
    <h1>Вітаємо!</h1>
    <SignIn />
  </div>
      )
    }


  </AuthUserContext.Consumer>



);

const condition = authUser => !!authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(Home);
