import React, { Component } from 'react';

import { withFirebase } from '../Firebase';

import Points from '../Points/PointsUser';

class UserItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      user: null,
      ...props.location.state,
      pointSum: 0,
      points: []
    };
  }

  componentDidMount() {


    this.onCountPoints();

    if (this.state.user) {
      return;
    }

    this.setState({ loading: true });

    this.props.firebase
      .user(this.props.match.params.id)
      .on('value', snapshot => {
        this.setState({
          user: snapshot.val(),
          loading: false,
        });
      });

  }



  onCountPoints() {

     this.setState({ loading: true });


     this.props.firebase
      .points()
      .orderByChild('userId')
      .equalTo(this.state.user.uid)
      .on('value', snapshot => {
        const pointsObject = snapshot.val();

        if (pointsObject) {
          const pointsList = Object.keys(pointsObject).map(key => ({
            ...pointsObject[key],
            uid: key,
          }));

          console.log(pointsList);

          const sum = pointsList.reduce((sum, item) =>{
            return sum += +item.amount;
          }, 0);

          console.log(sum);

          this.setState({
            pointSum: sum,
            loading: false
          });
        } else {
          this.setState({ pointSum: 0, loading: false });
        }
      });
  }

/*
  onListenForUser() {

  }



  onListenForUserPoints(){

    this.props.firebase
      .points()
      .orderBy('userId')
      .equalTo(this.state.user.uid)
      .on('value', snapshot => {
        const pointsObject = snapshot.val();

        if (pointsObject) {
          const pointsList = Object.keys(pointsObject).map(key => ({
            ...pointsObject[key],
            uid: key,
          }));

          console.log(pointsObject);

          this.setState({
            points: pointsList,
            loading: false,
          });
        } else {
          this.setState({ points: null, loading: false });
        }
      });

  }
*/



  componentWillUnmount() {
    this.props.firebase.user(this.props.match.params.id).off();
    this.props.firebase.points().off();
  }

  onSendPasswordResetEmail = () => {
    this.props.firebase.doPasswordReset(this.state.user.email);
  };

 

  render() {
    const { user, loading, pointSum } = this.state;

    return (
      <div className="useritem">
        <h2>Користувач ({this.props.match.params.id})</h2>
        {loading &&  <div className="lds-dual-ring"></div>}

        {!loading && user && (
          <div>
         
            <div>
              <strong>E-Mail:</strong> {user.email}
            </div>
            <div>
              <strong>Ім'я:</strong> {user.username}
            </div>
            <div><strong>Бали:</strong> {pointSum}</div>
            <div>
              <button
                type="button"
                onClick={this.onSendPasswordResetEmail}
              >
                Надіслати відновлення паролю
              </button>
            </div>
            <div>

            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withFirebase(UserItem);
