import React, { Component } from 'react';
import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';


import * as ROUTES from '../../constants/routes';
import { Link } from 'react-router-dom';


import EventChecker from './EventChecker';


import EventMap from './EventMap';






class EventItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
     // userId: this.props.authUser.uid,
     userCanCheck: true,
      userDidChecked: false,
      event: this.props.event,
      ...props.location,

    };
  }


  componentDidMount(){
     this.onListenEvent();
    //this.onDidUserCheck();
  }




 
  onListenEvent = () => {
    //const myevent = window.location.href.substr(window.location.href.lastIndexOf("/")+1);

  

    this.props.firebase.event("-MSe5goDz3UGD_N-YYx9").on('value', snapshot => {
      // console.log(snapshot.val());
       let eventObject = snapshot.val();

       this.setState({event: eventObject});
     //  console.log(this.props);
       //alert( this.props.match.params.id);
       //alert(this.state.event);
    });
  }
  


  onDidUserCheck = (authUser) => {

  //let userId = authUser.uid;
  
    this.props.firebase.event(this.props.event.uid).on('value', snapshot => {
      const eventObject = snapshot.val();

            ////// ПЕРЕРОБИТИ НОРМАЛЬНО ! + як вийти з циклу !!

      if(eventObject.usersChecked){
         if(Object.values(eventObject.usersChecked).find((item) => item.userId === this.state.userId )){
           this.setState({userDidChecked: true});
         }
      }

     //.points ? eventPoints += userObject.points : eventPoints = eventPoints; //тернарний опертаор тільки  2 аргументи

    });    
      
  }


  onCheckIn = (authUser) => {
 // let userId = authUser.uid;

    let eventLat = +(this.props.event.lat);
    let eventLon = +(this.props.event.lon);

    navigator.geolocation.getCurrentPosition((position) => {

    let userLat = +(position.coords.latitude).toFixed(5);
    let userLon = +(position.coords.longitude).toFixed(5);
 

    ///////OFF EVENTS DB !!!!

 

      //checker
     let eventPoints = this.props.event.points;

       this.props.firebase.user(this.state.userId).on('value', snapshot => {

         const userObject = snapshot.val();
         if(userObject.points) { eventPoints += userObject.points }//тернарний опертаор тільки  2 аргументи:

        });

       //ПЕРЕРОБИТИ !!!і відняти одне від другого по модулю
    if(  userLat <= eventLat + 0.00140 && userLat >= eventLat - 0.00140 && userLon <= eventLon + 0.00090 &&  userLon >= eventLon - 0.00090 ) {

          //get points 
          this.props.firebase.user(this.state.userId).update({
            points: eventPoints,
          });

          //change state 
          this.setState({userDidChecked: true});
       //   this.state.userDidChecked = true;

          //add check to list
          //let userId = this.state.userId;
          this.props.firebase.db.ref('events/' + this.props.event.uid +'/usersChecked').push({
            userId: this.state.userId,
            date: this.props.firebase.serverValue.TIMESTAMP
          });


    } else {
      alert("not checked !!");
    }
  
 }); //end navigator coords

} //end on check in


componentWillUnmount() {
    this.props.firebase.events().off();
    this.props.firebase.users().off(); //?????????????
  }




  render() {
    const { event } = this.props;
    //const {authUser} = this.props;
   // const { editMode, editTitle } = this.state;


//alert(this.state.event);
//const event = this.state.event;
    //const checked = this.state.userDidChecked;

   // console.log(event);

    return (

///// ВИДАЛИТИ ???і
      <AuthUserContext.Consumer>
        {authUser => (
      <div className="event">
        <div className="event__item">
            <div className="event__title">
             <h3>{event.title}</h3>
            </div>
            <div className="event__place">
            <p><strong>Місце:</strong> {event.place}</p>
              </div>
                <div className="event__time">
            <p><strong>Час:</strong> {event.timeStart}</p>
              </div>

{/*                 <Link className="event__link"
                  to={{
                    pathname: `${ROUTES.EVENTS}/${event.uid}`,
                    state: { event },
                  }}
                >
                  Детальніше
                </Link>
*/}
                <EventMap  coords={{lat: event.lat, lng: event.lon}} />

               <div className="event__check">
                  <EventChecker 
                    firebase={this.props.firebase} 
                    authUser={this.props.authUser} 
                    event={this.props.event}
                  >
                  </EventChecker>
               </div>


          </div>
      
          </div>
 




         
  
                  )}
      </AuthUserContext.Consumer>


        )
  }
}

export default withFirebase(EventItem);

/*
 -title: {event.title}
            -place: {event.place}
            -time: {event.startTime}
  <EventChecker 
      firebase={this.props.firebase} 
      authUser={this.props.authUser} 
  event={this.props.event}
    >
    </EventChecker>


             
              */