//export const START = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/';
export const ACCOUNT = '/account';
export const PASSWORD_FORGET = '/pw-forget';
export const ADMIN = '/admin';
export const ADMIN_DETAILS = '/admin/:id';
export const ADD_VOTE = '/add-vote';
export const VOTES = '/votes';
export const VOTE = '/votes/:id';
export const VOTE_PAGE = '/votes/:id';
export const CHAT = '/chat';
export const CHAT_ADMIN = '/chat-admin';
export const NEWS = '/news';
export const ADD_NEWS = '/add-news';
export const EVENTS = '/events';
export const EVENT = '/events/:id';
export const GROUPS = '/groups';
export const GROUP = '/group/:id';