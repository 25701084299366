import React from 'react';

import PointItem from './PointItem';

const PointsList = ({
  authUser,
  points,
  onEditPoint,
  onRemovePoint,
  users
}) => (
  <div className="points-list">
    {points.map(point => (
      <PointItem
        authUser={authUser}
        key={point.uid}
        point={point}
        onEditPoint={onEditPoint}
        onRemovePoint={onRemovePoint}
        users={users}
      />
    ))}
  </div>
);

export default PointsList;
