import React from 'react';

//import { AuthUserContext } from '../Session';
//import { withFirebase } from '../Firebase';
//import EventList from './EventList';

import { compose } from 'recompose';

//import { withAuthorization, withEmailVerification } from '../Session';
//import * as ROLES from '../../constants/roles';





import { withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  Circle
} from "react-google-maps";

const MyMapComponent = compose(
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyBYOH47MB5m8L3ZUW05X5UocLsk_awOVqw&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap
 
    onClick={event => {
      //console.log("latitide = ", ev.latLng.lat());
    //  console.log("longitude = ", ev.latLng.lng());
if(props.updateCoords) {

 props.updateCoords({lat:event.latLng.lat(), lon: event.latLng.lng() });
     // document.querySelector('input[name = "lat"]').value = ev.latLng.lat(); 
     // document.querySelector('input[name = "lon"]').value = ev.latLng.lng();


props.handleMarkerCoords({lat:event.latLng.lat(), lng: event.latLng.lng() });
} 

    }}
    defaultZoom={15}
    defaultCenter={props.markerCoords}
    options={{streetViewControl: false, mapTypeControl: false}}

  >
    
      <Marker

      position={props.markerCoords}

      
        onClick={props.onMarkerClick}
      />

      <Circle 

      center={props.markerCoords}
      radius={100}
      onClick={ () => console.log(this.getCenter())}
      options={{
        strokeColor:"#009ffd", 
        strokeOpacity:"1",
        strokeWeight:"2",
        fillColor:"#009ffd",
        fillOpacity:"0.2"
         }}



      />

   
  </GoogleMap>
));

class EventMap extends React.PureComponent {

 constructor(props){
   super(props);

   this.state = {
     markerCoords: this.props.coords,
     loading: true
   }
 }

  componentDidMount() {
    this.setState({loading: false})
  }

  handleMarkerClick = (ev) => {
     console.log("latitide = ", ev.latLng.lat());
      console.log("longitude = ", ev.latLng.lng());
  };


  handleMarkerCoords = (coords) => {
    this.setState({markerCoords: coords});

  }

  render() {

    const loading = this.state.loading;
    return (
      <div className="event__map">

       {loading &&  <div className="lds-dual-ring"></div>}

      { !loading && (<MyMapComponent
        updateCoords={this.props.updateCoords}
        handleMarkerCoords={this.handleMarkerCoords}
        onMarkerClick={this.handleMarkerClick}
        markerCoords={this.state.markerCoords}
      />)}
      </div>
    );
  }
}


export default EventMap;
