import React, { Component } from 'react';

//import { withAuthorization, withEmailVerification } from '../Session';
import * as ROLES from '../../constants/roles';



class PointItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      editText: this.props.point.text,
    };
  }

  onToggleEditMode = () => {
    this.setState(state => ({
      editMode: !state.editMode,
      editText: this.props.point.text,
    }));
  };

  onChangeEditText = event => {
    this.setState({ editText: event.target.value });
  };

  onSaveEditText = () => {
    this.props.onEditpoint(this.props.point, this.state.editText);

    this.setState({ editMode: false });
  };

  render() {
    const { authUser, point, onRemovepoint, users } = this.props;
    const { editMode, editText } = this.state;

    return (
      <div className="point">
        {editMode ? (
          <input
            type="text"
            value={editText}
            onChange={this.onChangeEditText}
          />
        ) : (
        <div className="point__body">
          <div className="point__data">
            Користувачу <strong> {users[point.userId].username} </strong> нараховано <strong> {point.amount} </strong> балів, причина: <strong> {point.reason} </strong>.
            </div>
             <div className="point__metadata">
            Нараховано {new Date(point.createdAt).toLocaleString()} {point.createdBy ? " користувачем " + users[point.createdBy].username : null }
            </div>
            <span className="point__edited">
            {point.editedAt && <span className="point-edited">(Edited)</span>}
          </span>
          </div>
        )}

        {!!authUser.roles[ROLES.ADMIN] && (
          <div className="point__admin">
            {editMode ? (
              <span>
                <button className="btn btn-small" onClick={this.onSaveEditText}>Зберегти</button>
                <button className="btn btn-small" onClick={this.onToggleEditMode}>Відмінити</button>
              </span>
            ) : (
              <button className="btn btn-small" onClick={this.onToggleEditMode}>Редагувати</button>
            )}

            {!editMode && (
              <button
                className="btn btn-small"
                type="button"
                onClick={() => onRemovepoint(point.uid)}
              >
                Видалити
              </button>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default PointItem;
