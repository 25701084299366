import React, { Component } from 'react';

import { AuthUserContext } from '../Session';
//import { withFirebase } from '../Firebase';


import { compose } from 'recompose';

import { withAuthorization, withEmailVerification } from '../Session';
import * as ROLES from '../../constants/roles';


import UserSelect from '../Users/UserSelect';



class PointNew extends Component {
  constructor(props) {
    super(props);

   this.state = {
            reason: '',
            amount: '',
            userId: ''
        };
  }

  componentDidMount() {

  }


  componentWillUnmount() {
    this.props.firebase.points().off();
  }

  onChangeReason = event => {
    this.setState({ reason: event.target.value });
  };

   onChangeAmount = event => {
    this.setState({ amount: event.target.value });
  };

  onChangeUserId = event => {
    this.setState({ userId: event.target.value });
  };


  onUserSelect = user => {
   this.setState({ userId: user });
  }

  

  onAddPoints = (event, authUser) => {
    let key = this.props.firebase.points().push({
      reason: this.state.reason,
      amount: this.state.amount,
      userId: this.state.userId,
      createdAt: this.props.firebase.serverValue.TIMESTAMP,
      createdBy: authUser.uid
    }).key;

    this.setState({ 
      reason: '',
      amount: '',
      userId: ''
    });
    

    event.preventDefault();
  };








  render() {



    const { reason, amount, userId } = this.state;

    //et timestamp = new Date(startTime).toLocaleFormat("2021-02-18T03:53");

    return (
      <AuthUserContext.Consumer>
        {authUser => (


          <div>


           <h2>Додати бали</h2>

            <form
              onSubmit={event =>
                this.onAddPoints(event, authUser)
              }
            >

            <div>
            <label>Причина: </label>
             <input
                type="text"
                value={reason}
                onChange={this.onChangeReason}
                required="required"
              />  
              </div>

              <div>
               <label>Кількість: </label>
             <input
                type="number"
                value={amount}
                onChange={this.onChangeAmount}
                required="required"
              /> 
              </div>

               <div>
             {/*
               <label>Користувач: </label>
             <input
                type="text"
                value={userId}
                onChange={this.onChangeUserId}
              /> 
*/}
              <UserSelect onUserSelect={this.onUserSelect} />
              </div>
       

        
             
              <button type="submit">Додати бали</button>
            </form>
          </div>


        )}
      </AuthUserContext.Consumer>
    );
  }
}

const condition = authUser =>
  authUser && !!authUser.roles[ROLES.ADMIN];

export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(PointNew);


