import React, { Component } from 'react';
//import { Link } from 'react-router-dom';

import { withFirebase } from '../Firebase';
//import * as ROUTES from '../../constants/routes';

class UserSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      users: [],
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    this.props.firebase.users().on('value', snapshot => {
      const usersObject = snapshot.val();

      const usersList = Object.keys(usersObject).map(key => ({
        ...usersObject[key],
        uid: key,
      }));

      this.setState({
        users: usersList,
        loading: false,
      });
    });
  }


onSelect = event => {
  this.props.onUserSelect(event.target.value);
}

/*
  onSelectUser = event => {
      this.setState({ state: event.target.value});
  }
*/


  componentWillUnmount() {
    this.props.firebase.users().off();
  }

  render() {
    const { users, loading } = this.state;

    return (
      <div>
        <h2>Користувачі</h2>
        {loading && <div className="lds-dual-ring"></div>}
        <div className="users__select">

 <select required="required" defaultValue="" name="users" id="users" onChange={this.onSelect}>
         <option value="">Виберіть користувача</option>
          {users.map(user => (


             <option key={user.uid} value={user.uid}>{user.username}</option>

          ))}
          </select>
        </div>
      </div>
    );
  }
}

export default withFirebase(UserSelect);

