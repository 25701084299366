import React, { Component } from 'react';

import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
import PointsList from './PointsList';




class Points extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: '',
      loading: false,
      points: [],
      limit: 5,
      users: {}
    };
  }

  componentDidMount() {
    this.onListenForPoints();
  }

  onListenForPoints = () => {
    this.setState({ loading: true });



    this.props.firebase
      .users()
      .orderByChild('createdAt')
      .on('value', snapshot => {
        const usersObject = snapshot.val();

      
//console.log("!!!!1");
//console.log(usersObject);

          this.setState({
            users: usersObject,
          });
        
      });




    this.props.firebase
      .points()
      .orderByChild('createdAt')
      .limitToLast(this.state.limit)
      .on('value', snapshot => {
        const pointObject = snapshot.val();

        if (pointObject) {
          const pointList = Object.keys(pointObject).map(key => ({
            ...pointObject[key],
            uid: key,
          }));

          const pointListReversed = pointList.reverse();


          this.setState({
            points: pointListReversed,
            loading: false,
          });
        } else {
          this.setState({ points: null, loading: false });
        }
      });
  };






  componentWillUnmount() {
    this.props.firebase.points().off();
    this.props.firebase.users().off();
  }

  onChangeText = event => {
    this.setState({ text: event.target.value });
  };

  onCreatePoint = (event, authUser) => {
    this.props.firebase.points().push({
      text: this.state.text,
      userId: authUser.uid,
      createdAt: this.props.firebase.serverValue.TIMESTAMP,
    });

    this.setState({ text: '' });

    event.preventDefault();
  };

  onEditPoint = (point, text) => {
    const { uid, ...pointSnapshot } = point;

    this.props.firebase.point(point.uid).set({
      ...pointSnapshot,
      text,
      editedAt: this.props.firebase.serverValue.TIMESTAMP,
    });
  };

  onRemovePoint = uid => {
    this.props.firebase.point(uid).remove();
  };

  onNextPage = () => {
    this.setState(
      state => ({ limit: state.limit + 5 }),
      this.onListenForPoints,
    );
  };

  render() {
    const { points, loading, users } = this.state;
    //const text = this.state.text; 

    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className="points-list-wrap">
            

            {loading && <div>Завантаження ...</div>}

            {points && (
              <PointsList
                authUser={authUser}
                points={points}
                onEditPoint={this.onEditPoint}
                onRemovePoint={this.onRemovePoint}
                users={users}
              />
            )}

            {!loading && points && (
              <button type="button" onClick={this.onNextPage}>
                Більше
              </button>
            )}

            {!points && <div>Немає балів ...</div>}

          
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

export default withFirebase(Points);
