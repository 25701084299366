import React from 'react';

import NewsItem from './NewsItem';

const NewsList = ({
  authUser,
  news,
  onEditNews,
  onRemoveNews,
}) => (
  <div className="news-list">
    {news.map(news => (
      <NewsItem
        authUser={authUser}
        key={news.uid}
        news={news}
        onEditNews={onEditNews}
        onRemoveNews={onRemoveNews}
      />
    ))}
  </div>
);

export default NewsList;
