import React from 'react';
//import { withFirebase } from '../Firebase';



import { compose } from 'recompose';
import { AuthUserContext } from '../Session';
import { withAuthorization, withEmailVerification } from '../Session';
import * as ROLES from '../../constants/roles';


class NewsNew extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      text: ''
    };
  }



  onCreateNews = (event, authUser) => {
    this.props.firebase.news().push({
      text: this.state.text,
      userId: authUser.uid,
      createdAt: this.props.firebase.serverValue.TIMESTAMP,
    });

    this.setState({ text: '' });

    event.preventDefault();
  };

  onChangeText = event => {
    this.setState({ text: event.target.value });
  }



  render() {

     const text = this.state.text;


    return (

 <AuthUserContext.Consumer>
{authUser => (
       <form
              onSubmit={event =>
                this.onCreateNews(event, authUser)
              }>
              <input
                type="text"
                value={text}
                onChange={this.onChangeText}
                required="required"
              />
              <button type="submit">Надіслати</button>
            </form>
            )}
  </AuthUserContext.Consumer>
            
      );
  }
}


const condition = authUser =>
  authUser && !!authUser.roles[ROLES.ADMIN];

export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(NewsNew);


/*




onCreateNews = (event, authUser) => {
    this.props.firebase.news().push({
      text: this.state.text,
      userId: authUser.uid,
      createdAt: this.props.firebase.serverValue.TIMESTAMP,
    });

    this.setState({ text: '' });

    event.preventDefault();
  };





{ !!authUser.roles[ROLES.ADMIN] && (
            <form
              onSubmit={event =>
                this.onCreateNews(event, authUser)
              }>
              <input
                type="text"
                value={text}
                onChange={this.onChangeText}
              />
              <button type="submit">Надіслати</button>
            </form>
          )}


*/