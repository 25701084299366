import React from 'react';

import VoteItem from './VoteItem';

/*


class VoteList extends Component {
  constructor(props){
    super(props);

  this.state = {
      authUser: this.props.authUser,
      votes:this.props.votes,
      results:this.props.results,
      onEditVote:this.props.onEditVote,
      onRemoveVote:this.props.onRemoveVote,
      onSubmitVote:this.props.onSubmitVote
    }

}


render(){




  return (
        <div className="votes-list">
    {this.state.votes.map(vote => (
      <VoteItem
        authUser={this.state.authUser}
        key={vote.uid}
        vote={vote}
        result={this.state.results.filter((item)=>item.voteId == vote.uid)}
        onEditVote={this.state.onEditVote}
        onRemoveVote={this.state.onRemoveVote}
        onSubmitVote={this.state.onSubmitVote}
      />
    ))}
  </div>
    );
}



}
*/



const VoteList = ({
  authUser,
  votes,
  results,
  onEditVote,
  onRemoveVote,
  onSubmitVote
}) => (
  <div className="votes-list">
    {votes.map(vote => (
      <VoteItem
        authUser={authUser}
        key={vote.uid}
        vote={vote}
        result={results}
        onEditVote={onEditVote}
        onRemoveVote={onRemoveVote}
        onSubmitVote={onSubmitVote}
      />
    ))}
  </div>
);




export default VoteList;
