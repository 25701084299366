import React, {Component} from 'react';
//import { Switch, Route } from 'react-router-dom';
//import { compose } from 'recompose';

//import { withAuthorization, withEmailVerification } from '../Session';



//import VoteItem from '../Votes/VoteItem';

//import * as ROUTES from '../../constants/routes';




//import { withFirebase } from '../Firebase';





class VotePage extends Component {
		constructor(props) {
    		super(props);

		 this.state = {
		      loading: false,
		      vote: null,
		      ...props.location.state,
		    };

}


 componentDidMount() {
    if (this.state.vote) {
      return;
    }

    this.setState({ loading: true });

    this.props.firebase
      .votes(this.props.match.params.id)
      .on('value', snapshot => {
        this.setState({
          vote: snapshot.val(),
          loading: false,
        });
      });
  }

  componentWillUnmount() {
    this.props.firebase.votes(this.props.match.params.id).off();
  }


  render() {



   // const { title, votes, loading, results } = this.state;

    return (
			 <div className="votesPAGE">


					<p>ДОРОУ</p>

			 </div>



    	);
}

}




export default VotePage;