import React  from 'react';
import {useState} from 'react';
//import { getToken, onMessageListener } from './firebase';


import { withFirebase } from '../Firebase';


//import {Button, Row, Col, Toast} from 'react-bootstrap';



function FcmApp(props) {

  const [ setShow] = useState(false);
   // const [show] = useState(false);
  const [notification, setNotification] = useState({title: '', body: ''});
  const [isTokenFound, setTokenFound] = useState(false);
  props.firebase.getToken(setTokenFound);

  props.firebase.onMessageListener().then(payload => {
    /*setShow(true);*/
    setNotification({title: payload.notification.title, body: payload.notification.body})
    console.log(payload);

    //firebsase
  }).catch(err => console.log('failed: ', err));

  return (
    <div className="App">
    {/*
        <div onClose={() => setShow(false)} show={show} delay={3000} autohide animation style={{
          position: 'absolute',
          top: 20,
          right: 20,
          minWidth: 200
        }}>
        */}
        <h2>Пуш повідомлення</h2>
    <p>Дозвольте додатку надсилати Вам повідомлення</p>
      <header className="App-header">
        {isTokenFound && <h3> Повідомлення дозволені 👍🏻 </h3>}
        {!isTokenFound && <h3> Повідомлення заборонені ❗️ </h3>}
        {/* <button onClick={() => setShow(true)}>Show Toast</button> */}
      </header>


             <div>
          <div>
            <strong className="mr-auto">{notification.title}</strong>
          </div>
          <div>{notification.body}</div>
        </div>


</div>

  );

}

export default withFirebase(FcmApp);