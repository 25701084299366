import React, { Component } from 'react';

import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
import VoteList from './VoteList';
//import VoteNew from './VoteNew';

class Votes extends Component {
  constructor(props) {
    super(props);

  this.state = {
      text: '',
      loading: false,
      messages: [],
      limit: 5,
    };

       // this.onChangeOption = this.onChangeOption.bind(this); //////////&???????????????????????????????????
  }

  componentDidMount() {
this.onListenForVotes();
this.onListenForResults();
  }



onListenForResults = () => {

    this.props.firebase
      .voteAnswers()
      .orderByChild('votedAt')
      .on('value', snapshot => {
        const resultsObject = snapshot.val();

        if (resultsObject) {
          const resultsList = Object.keys(resultsObject).map(key => ({
            ...resultsObject[key],
            uid: key,
          }));

          this.setState({
            results: resultsList,
            loading: false,
          });
        } else {
          this.setState({ results: null, loading: false });
        }
      });
    };



  onListenForVotes = () => {
    this.setState({ loading: true });

    this.props.firebase
      .votes()
      .orderByChild('createdAt')
      .limitToLast(this.state.limit)
      .on('value', snapshot => {
        const voteObject = snapshot.val();

        if (voteObject) {
          const voteList = Object.keys(voteObject).map(key => ({
            ...voteObject[key],
            uid: key,
          }));

          const voteListReversed = voteList.reverse();

          this.setState({
            votes: voteListReversed,
            loading: false,
          });
        } else {
          this.setState({ votes: null, loading: false });
        }
      });
  };


  onListenForOptions =() =>{
   // let voteList = this.state.votes;
  }
  

  componentWillUnmount() {
    this.props.firebase.votes().off();
  }

  onChangeText = event => {
    this.setState({ title: event.target.value });
  };


  onChangeOption = (i, event) => {

  //  options: [
  //              { option: '', optionError: '' },
  //              { option: '', optionError: '' }
  //          ]
    //value={this.state.options[i].option}
    // this.setState({ title: event.target.value });
  //  let i = event.target.data_id;
   // let optionsNew = this.state.options;
    // optionsNew[i].option = event.target.value;

     let options = this.state.options;
        options[i].option = event.target.value;
        this.setState({ options: options });


     // this.setState({ title: i });
  }

  onCreateVote = (event, authUser) => {
    this.props.firebase.votes().push({
      title: this.state.title,
      userId: authUser.uid,
      createdAt: this.props.firebase.serverValue.TIMESTAMP,
      options: this.state.options
    });

    this.setState({ title: '' });

    event.preventDefault();
  };

  onEditVote = (vote, text) => {
    const { uid, ...voteSnapshot } = vote;

    this.props.firebase.vote(vote.uid).set({
      ...voteSnapshot,
      text,
      editedAt: this.props.firebase.serverValue.TIMESTAMP,
    });
  };

  onRemoveVotes = uid => {
    this.props.firebase.vote(uid).remove();
  };

  onNextPage = () => {
    this.setState(
      state => ({ limit: state.limit + 5 }),
      this.onListenForVotes,
    );
  };


onSubmitVote = (voteId, optionId, userId) => {

  this.props.firebase.voteAnswers().push({
      userId: userId,
      optionId: optionId,
      voteId: voteId,
      votedAt: this.props.firebase.serverValue.TIMESTAMP
    });




}




     handleAddOption= () => {
        let options = this.state.options;
        options.push({ option: '', optionError: '' });

        this.setState({ options }); //es6 shorthand for { options: options }
    }


    handleSubmit = (e) => {
      e.preventDefault();

      this.props.firebase.votes().push({
      title: this.state.title,
     // userId: authUser.uid,
      createdAt: this.props.firebase.serverValue.TIMESTAMP,
      options: this.options
    });
    }
  render() {



    const { votes, loading, results } = this.state;

/* const title = this.state.title; */

    return (
      <AuthUserContext.Consumer>
        {authUser => (


          <div className="votes-list-wrap">
<h1>Голосування</h1>




            { loading && <div className="lds-dual-ring"></div>}

            {votes && (
              <VoteList
                authUser={authUser}
                votes={votes}
                results={results}
                onEditMessage={this.onEditMessage}
                onRemoveMessage={this.onRemoveMessage}
                 onSubmitVote={this.onSubmitVote}
              />
            )}

            {!votes && <div>Немає голосувань</div>}
            
            { !loading &&  votes && (
              <button type="button" onClick={this.onNextPage}>
                Більше
              </button>
            )}

    

          </div>


        )}
      </AuthUserContext.Consumer>
    );
  }
}

export default withFirebase(Votes);
