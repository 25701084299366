import React, { Component } from 'react';
//import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';


//import * as ROUTES from '../../constants/routes';
//import { Link } from 'react-router-dom';







class EventChecker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userId: this.props.authUser.uid,
      userDidChecked: false,
      userCanCheck: true
     //firebase: this.props.firebase
    };
  }




  componentDidMount(){
    this.onDidUserCheck();
    this.userCanCheck();
  }

  userCanCheck = () => {

  //  alert(new Date(this.props.event.timeEnd).getTime() + " fesef " + this.props.firebase.serverValue.TIMESTAMP);
      // console.log("99999999999999");
    //console.log(this.props.firebase.serverValue.TIMESTAMP);
   // console.log(new Date().getTime());

    if(new Date(this.props.event.timeEnd).getTime()  <= new Date().getTime()) {
      this.setState({userCanCheck: false});
    }
  }


 // return url.substr(url.lastIndexOf("/")+1);
//new Date('2012.08.10').getTime() / 1000



  onDidUserCheck = () => {

    //console.log(this.props.firebase);
  
    this.props.firebase.event(this.props.event.uid).on('value', snapshot => {
      const eventObject = snapshot.val();


      //console.log("!!");
      //console.log(eventObject.usersChecked);

            ////// ПЕРЕРОБИТИ НОРМАЛЬНО ! + як вийти з циклу !!

      if(eventObject.usersChecked){
         if(Object.values(eventObject.usersChecked).find((item) => item.userId === this.state.userId )){
           this.setState({userDidChecked: true});
         }
      }

     //.points ? eventPoints += userObject.points : eventPoints = eventPoints; //тернарний опертаор тільки  2 аргументи

    });    
      
  }


  onCheckIn = (authUser) => {


    let eventLat = +(this.props.event.lat).toFixed(5);
    let eventLon = +(this.props.event.lon).toFixed(5);

    let eventTimeStart = new Date(this.props.event.timeStart).getTime();
    let eventTimeEnd = new Date(this.props.event.timeEnd).getTime();
    let currentTime = new Date().getTime();


    if(currentTime <= eventTimeEnd && currentTime >= eventTimeStart) {

    navigator.geolocation.getCurrentPosition((position) => {

    let userLat = +(position.coords.latitude).toFixed(5);
    let userLon = +(position.coords.longitude).toFixed(5);
 

    ///////OFF EVENTS DB !!!!

 

      //checker
     let eventPoints = this.props.event.points;

       this.props.firebase.user(this.state.userId).on('value', snapshot => {

         const userObject = snapshot.val();
         if(userObject.points) { eventPoints += userObject.points} //тернарний опертаор тільки  2 аргументи:

        });




       //ПЕРЕРОБИТИ !!!і відняти одне від другого по модулю
    if(  userLat <= eventLat + 0.00140 && userLat >= eventLat - 0.00140 && userLon <= eventLon + 0.00900 &&  userLon >= eventLon - 0.00900 ) {

          //get points 
         // this.props.firebase.user(this.state.userId).update({
         //   points: eventPoints,
         // });

          //change state 
          this.setState({userDidChecked: true});
         // this.state.userDidChecked = true;

          //add check to list in event
          //let userId = this.state.userId;
          this.props.firebase.db.ref('events/' + this.props.event.uid +'/usersChecked').push({
            userId: this.state.userId,
            date: this.props.firebase.serverValue.TIMESTAMP
          });

          //add check to list points
          this.props.firebase.points().push({
                amount: eventPoints,
                userId: this.state.userId,
                createdAt: this.props.firebase.serverValue.TIMESTAMP,
                reason: "visited event " + this.props.event.title
            });


    } else {
      alert("not checked !! place ");
     // console.log("888888");
    //  console.log(userLat + " " + userLon );
      //console.log(eventLat + " " + eventLon );
    }
  
 }, () => {alert("error")}); //end navigator coords
 
  } else {  alert("not checked !! time" + eventTimeEnd + ' ' + currentTime); } // end if time

} //end on check in


componentWillUnmount() {
    this.props.firebase.events().off();
    this.props.firebase.users().off(); //?????????????
  }




render(){

  const checked = this.state.userDidChecked;
  const canCheck = this.state.userCanCheck;
 // const authUser = this.props.authUser; ЧОМУ НЕ ПРАЦЮЄ

  return (
    <div>

          {!checked && canCheck && (
            <button 
               type="button"
               onClick={this.onCheckIn}
              >
                Зачекінитись !
        </button>
         )}

            {checked && ( 
              <p>Ти зачекінився!</p>
              )}


              {!canCheck && !checked &&( 
              <p>Подія закінчилась</p>
              )}
    </div>

    );
}


}


export default withFirebase(EventChecker);


/*

 onCreateMessage = (event, authUser) => {
    this.props.firebase.messages().push({
      text: this.state.text,
      userId: authUser.uid,
      createdAt: this.props.firebase.serverValue.TIMESTAMP,
    });

    this.setState({ text: '' });

    event.preventDefault();
  };

  */