import React from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

import Navigation from '../Navigation';
import Start from '../Start';
import SignUpPage from '../SignUp';
import SignInPage from '../SignIn';
import PasswordForgetPage from '../PasswordForget';
import Home from '../Home';
import AccountPage from '../Account';
import AdminPage from '../Admin';
//import Messages from '../Messages';
import Chat from '../Chat';

//import VoteNew from '../Votes/VoteNew';
import VotePage from '../Votes/VotePage';

import News from '../News';
import Events from '../Events';
//import Groups from '../Groups';
import Votes from '../Votes';


import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';



//import firebase from "firebase/app";
//import "firebase/messaging";


import smLogo from '../../images/sm-logo.png'


import HttpsRedirect from 'react-https-redirect';

/*
function getMessagingObject() {
  // [START messaging_get_messaging_object]
  const messaging = firebase.messaging();
  // [END messaging_get_messaging_object]
}

function receiveMessage() {
  const messaging = firebase.messaging();
  // [START messaging_receive_message]
  // Handle incoming messages. Called when:
  // - a message is received while the app has focus
  // - the user clicks on an app notification created by a service worker
  //   `messaging.onBackgroundMessage` handler.
  messaging.onMessage((payload) => {
    console.log('Message received. ', payload);
    // ...
  });
  // [END messaging_receive_message]
}

function getToken() {
  const messaging = firebase.messaging();
  // [START messaging_get_token]
  // Get registration token. Initially this makes a network call, once retrieved
  // subsequent calls to getToken will return from cache.
  messaging.getToken({ vapidKey: '<YOUR_PUBLIC_VAPID_KEY_HERE>' }).then((currentToken) => {
    if (currentToken) {
      // Send the token to your server and update the UI if necessary
      // ...
    } else {
      // Show permission request UI
      console.log('No registration token available. Request permission to generate one.');
      // ...
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // ...
  });
  // [END messaging_get_token]
}

function requestPermission() {
  // [START messaging_request_permission]
  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      console.log('Notification permission granted.');
      // TODO(developer): Retrieve a registration token for use with FCM.
      // ...
    } else {
      console.log('Unable to get permission to notify.');
    }
  });
  // [END messaging_request_permission]
}

function deleteToken() {
  const messaging = firebase.messaging();

  // [START messaging_delete_token]
  messaging.deleteToken().then(() => {
    console.log('Token deleted.');
    // ...
  }).catch((err) => {
    console.log('Unable to delete token. ', err);
  });
  // [END messaging_delete_token]
}
*/


const App = () => (

  <Router>
    <div className="page">

      <div className="header">
      <div className="logo">
      <Link to={ROUTES.HOME}>
        <img width="100px" src={smLogo} alt="logo"/>
      </Link>
      </div>
      <Navigation />
      </div>

      <hr />

      <Route exact path={ROUTES.HOME} component={Home} />
      <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
      <Route path={ROUTES.SIGN_IN} component={SignInPage} />
      <Route
        path={ROUTES.PASSWORD_FORGET}
        component={PasswordForgetPage}
      />
      <Route path={ROUTES.ACCOUNT} component={AccountPage} />
      <Route path={ROUTES.ADMIN} component={AdminPage} />
      <Route path={ROUTES.CHAT} component={Chat} />
      <Route path={ROUTES.CHAT_ADMIN} component={Chat} />
      <Route path={ROUTES.NEWS} component={News} />

      <Route path={ROUTES.VOTE_PAGE} component={VotePage} />
      <Route path={ROUTES.EVENTS} component={Events} />
      <Route path={ROUTES.VOTES} component={Votes} />
    </div>
  </Router>
  
);

export default withAuthentication(App);
