import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

class UserList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      users: [],
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    this.props.firebase.users().on('value', snapshot => {
      const usersObject = snapshot.val();

      const usersList = Object.keys(usersObject).map(key => ({
        ...usersObject[key],
        uid: key,
      }));

     // console.log(usersList);

      this.setState({
        users: usersList
      });
    });



     this.props.firebase
      .points()
      .orderByChild('userId')
      .on('value', snapshot => {
        const pointsObject = snapshot.val();

        if (pointsObject) {
          const pointsList = Object.keys(pointsObject).map(key => ({
            ...pointsObject[key],
            uid: key,
          }));

          const users = this.state.users;

          users.forEach((user) => {
            const sum = pointsList.reduce((sum,item) => {

              if(item.userId == user.uid) {
                sum += +item.amount;
              } // ефективніший алгоримт

              return sum;

            }, 0);


            user.points = sum;
          });

        // console.log(users);
/*
          const sum = pointsList.reduce((sum, item) =>{
            return sum += +item.amount;
          }, 0);

          console.log(sum);
*/
          this.setState({
            loading: false
          });
        } else {
          this.setState({loading: false });
        }
      });




   // this.onCountPoints();
   // this.sortUsersRating();
  }




    onCountPoints() {

     this.setState({ loading: true });


     this.props.firebase
      .points()
      .orderByChild('userId')
      .on('value', snapshot => {
        const pointsObject = snapshot.val();

        if (pointsObject) {
          const pointsList = Object.keys(pointsObject).map(key => ({
            ...pointsObject[key],
            uid: key,
          }));

          const users = this.state.users;

          users.forEach((user) => {
            const sum = pointsList.reduce((sum,item) => {

              if(item.userId == user.uid) {
                sum += +item.amount;
              } // ефективніший алгоримт

              return sum;

            }, 0);


            user.points = sum;
          });

        // console.log(users);
/*
          const sum = pointsList.reduce((sum, item) =>{
            return sum += +item.amount;
          }, 0);

          console.log(sum);
*/
          this.setState({
            loading: false
          });
        } else {
          this.setState({loading: false });
        }
      });
  }


  sortUsersRating = () => {
    this.setState({loading: true});
    let users = this.state.users;
    users = users.sort((a,b) => (a.points< b.points) ? 1 : ((b.points < a.points) ? -1 : 0));
    this.setState({users: users, loading:false});
  }




  componentWillUnmount() {
    this.props.firebase.users().off();
    this.props.firebase.points().off();
  }

  render() {
    const { users, loading } = this.state;

     

    users.sort((a,b) => (a.points< b.points) ? 1 : ((b.points < a.points) ? -1 : 0))

    return (
      <div>
        <h2>Користувачі</h2>
        {loading && <div className="lds-dual-ring"></div>}
        <div className="userlist">
          {users.map(user => (
            <div key={user.uid} className="userlist__item user">
              <div className="user__email">
                <strong>E-Mail:</strong> {user.email}
              </div>
              <div className="user__username">
                <strong>Ім'я:</strong> {user.username}
              </div>
              <div>
              <strong>Бали:</strong> {user.points} 
              </div>
              <div className="user__link">
                <Link className="btn-link"
                  to={{
                    pathname: `${ROUTES.ADMIN}/${user.uid}`,
                    state: { user },
                  }}
                >
                  Деталі
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default withFirebase(UserList);
