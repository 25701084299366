import React, { Component } from 'react';

//import { withAuthorization, withEmailVerification } from '../Session';
import * as ROLES from '../../constants/roles';


class NewsItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      editText: this.props.news.text,
    };
  }

  onToggleEditMode = () => {
    this.setState(state => ({
      editMode: !state.editMode,
      editText: this.props.news.text,
    }));
  };

  onChangeEditText = event => {
    this.setState({ editText: event.target.value });
  };

  onSaveEditText = () => {
    this.props.onEditNews(this.props.news, this.state.editText);

    this.setState({ editMode: false });
  };

  render() {
    const { authUser, news, onRemoveNews } = this.props;
    const { editMode, editText } = this.state;


    const date = new Date(news.createdAt).toLocaleString();

    return (
      <div className="newsitem" data-author={news.userId}>
        {editMode ? (
          <input
            type="text"
            value={editText}
            onChange={this.onChangeEditText}
          />
        ) : (
          <div className="newsitem__body">
          <div className="newsitem__date">
            {date}
            </div>
            <div className="newsitem__text">
            {news.text}
            {news.editedAt && <span className="newsitem__edited edited">{'(Редаговано ' + new Date(news.editedAt).toLocaleString() + ' )'}</span>}
            </div>
            
          
            {/* news.editedAt && <span>(Edited)</span> */}
          </div>
        )}

        {!!authUser.roles[ROLES.ADMIN] && (
          <span className="newsitem__admin">
            {editMode ? (
              <span>
                <button className="btn btn-small" onClick={this.onSaveEditText}>Зберегти</button>
                <button className="btn btn-small" onClick={this.onToggleEditMode}>Відмінити</button>
              </span>
            ) : (
              <button className="btn btn-small" onClick={this.onToggleEditMode}>Редагувати</button>
            )}

            {!editMode && (
              <button
                className="btn btn-small"
                type="button"
                onClick={() => onRemoveNews(news.uid)}
              >
                Видалити
              </button>
            )}
          </span>
        )}
      </div>
    );
  }
}

export default NewsItem;
