export const CODES = {

"auth/invalid-email": "Неправильний формат пошти",
"auth/wrong-password": "Невірний пароль",
"auth/user-not-found": "Користувача не знайдено",
"auth/email-already-in-use": "!!! An account with this E-Mail address already exists. Try to login with this account instead. If you think the account is already used from one of the social logins, try to sign in with one of them. Afterward, associate your accounts on your personal account page.",
'auth/account-exists-with-different-credential': " !!! An account with an E-Mail address to this social account already exists. Try to login from this account instead and associate your social accounts on your personal account page.",
'auth/weak-password': 'Занадто слабкий пароль',

'password-changed': "Пароль змінено"
};
